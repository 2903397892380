import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = ['signatureInput']

  initialize() {
    if (this.signaturePadObject == undefined) this.initializeSignaturePad()
  }

  initializeSignaturePad() {
    let canvas = document.querySelector("canvas");

    if (canvas) {
      let parentWidth = canvas.parentElement.clientWidth
      canvas.setAttribute("width", parentWidth);

      this.signaturePadObject = new SignaturePad(canvas)

      return this.signaturePadObject
    }
  }

  clearSignaturePad() {
    this.signaturePadObject.clear();
  }

  checkSignatureOnSubmit(event) {
    if (this.signaturePadObject.isEmpty()) {
      alert('You need to sign the agreement to continue');
      event.preventDefault()
    } else {
      this.signatureInputTarget.value = this.signaturePadObject.toDataURL()
    }
  }
}
