import bulmaCalendar from "bulma-calendar";

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.datepicker')) {
    let options = {
      color: 'info',
      dateFormat: 'yyyy-MM-dd',
      enableMonthSwitch: false,
      enableYearSwitch: false,
      showHeader: false,
      showFooter: false,
      weekStart: 1
    }

    const datePickers = bulmaCalendar.attach('.datepicker', options)
    datePickers.forEach((datePicker) => {
      datePicker.on('save', function(picker) { picker.data.element.dispatchEvent(new Event('change')) });
    })
  }
})
