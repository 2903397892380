import { Controller } from "stimulus"
import Rails from "@rails/ujs"
let map = require('lodash/map');
export default class extends Controller {
  static targets = [
    "salaryFrequency", "salaryLabel", "salaryAmount", "salaryDay"
  ]

  connect() {
    if (this.hasSalaryLabelTarget) {
      this.setSalaryLabel()
    }
    if (this.hasSalaryFrequencyTarget) {
      this.setSalaryDayOptions()
    }
    this.scrollToAndClearFieldsWithErrors()
    this.setClassOnDisabledSelects()
  }

  openDatePicker(event) {
    event.preventDefault();
    event.currentTarget.showPicker()
  }

  zeroIfClear(event) {
    if (event.currentTarget.value === '') {
      event.currentTarget.value = '0'
    }
  }

  clearIfZero(event) {
    if (event.currentTarget.value === '0') {
      event.currentTarget.value = ''
    }
  }

  scrollToAndClearFieldsWithErrors() {
    let fieldsWithErrors = document.querySelectorAll('.field_with_errors')
    if (fieldsWithErrors.length > 0) {
      let currentField = document.querySelector('.field_with_errors')
      currentField.scrollIntoView({ block: 'center', behavior: 'smooth' });
      currentField.focus()

      // loop through all the fields with errors and add change event listener to them
      fieldsWithErrors.forEach(field => {
        // Gets the parent of the parent element and get the correct input, select, checkbox or textarea
        if (field.closest(':nth-child(2)').querySelector('input, select, checkbox, textarea').type === 'select-one') {
          field.addEventListener('change', function () {
            field.closest(':nth-child(2)').querySelector('.is-danger').innerHTML = ''
          });
        } else  {
          field.addEventListener('input', function () {
            field.parentElement.querySelector('.is-danger').innerHTML = ''
          });
        }
      })
    }
  }

  setClassOnDisabledSelects() {
    let disabledSelects = document.querySelectorAll('select:disabled')
    disabledSelects.forEach(field => {
      field.parentElement.classList.add('is-disabled')
    })
  }

  toOption(value) {
    return `<option value='${value[1]}'>${value[0]}</option>`
  }

  setSalaryDayOptions() {
    let selectedOption = this.salaryDayTarget.value
    let formData = new FormData()
    formData.append('salary_frequency', this.salaryFrequencyTarget.value)

    let externalContext = this

    Rails.ajax({
      type: 'POST',
      url: '/loan_agreements/salary_day_options',
      data: formData,
      success: function (data) {
        let days = map(data.options, externalContext.toOption).join('')
        externalContext.salaryDayTarget.innerHTML = days
        externalContext.salaryDayTarget.value = selectedOption
      },
    })
  }

  setSalaryLabel() {
    switch (this.salaryFrequencyTarget.value) {
      case 'every_week':
        this.salaryLabelTarget.textContent = "Salary Rand (per week, after tax and deductions)"
        break;
      case 'every_second_week':
        this.salaryLabelTarget.textContent = "Salary Rand (per 2 weeks, after tax and deductions)"
        break;
      case 'every_month':
        this.salaryLabelTarget.textContent = "Salary Rand (per month, after tax and deductions)"
        break;
      default:
        this.salaryLabelTarget.textContent = "Salary Rand (after tax and deductions)"
    }
  }
}
