import { Controller } from "stimulus"
import * as bulmaToast from 'bulma-toast'

export default class extends Controller {
  static targets = ["toast"]

  connect() {
    if (this.hasToastTarget) {
      bulmaToast.setDoc(window.document)

      bulmaToast.toast({
        message: this.toastTarget.innerHTML,
        duration: 4000,
        type: this.toastTarget.dataset.notificationType,
        opacity: 0.9,
        position: 'top-center',
      })
    }
  }
}
