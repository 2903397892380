import { DirectUpload } from "@rails/activestorage"
import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['loanAgreementId', 'directFileInput', 'fileNames', 'secureUpload', 'secureUploadButton', 'continueButton', 'uploadStatus']

  connect() {
    if (this.hasContinueButtonTarget && this.unsuccessfullCollection()) {
      this.disableContinueButton()
    }

    if (this.hasLoanAgreementIdTarget) {
      if (this.successfullCollection()) {
        this.disableSecureUpload()
        this.enableContinueButton()
      }
    }
  }

  loanAgreementId() {
    return this.loanAgreementIdTarget.getAttribute('id')
  }

  successfullCollection() {
    return this.loanAgreementIdTarget.getAttribute('status') === 'COLLECT_SUCCESS'
  }

  unsuccessfullCollection() {
    return this.loanAgreementIdTarget.getAttribute('status') !== 'COLLECT_SUCCESS'
  }

  enableContinueButton() {
    this.continueButtonTarget.classList.remove('is-hidden')
  }

  disableContinueButton() {
    this.continueButtonTarget.classList.add('is-hidden')
  }

  disableSecureUpload() {
    this.secureUploadTarget.style.pointerEvents = 'none'
    this.secureUploadButtonTarget.classList.remove('is-primary')
    this.secureUploadButtonTarget.classList.add('is-static')
  }

  uploadFiles() {
    Array.from(this.directFileInputTarget.files).forEach(file => this.uploadFile(file))
    this.directFileInputTarget.value = null
  }

  uploadFile = (file) => {
    // Disable continue button while uploading
    this.disableContinueButton()
    this.uploadStatusTarget.innerHTML = '<i class="fas fa-spinner fa-spin has-text-grey-light"></i>'
    const input = this.directFileInputTarget
    const url = input.dataset.directUploadUrl
    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        this.uploadStatusTarget.innerHTML = '<i class="fas fa-upload"></i>'
        this.enableContinueButton()
        alert('Could not upload file!')
      } else {
        // Add hidden input to the form with value of blob.signed_id
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = input.name
        document.querySelector('form').appendChild(hiddenField)
        this.fileNamesTarget.innerHTML += "<br>"
        this.fileNamesTarget.innerHTML += blob.filename
        // Enable continue button again
        this.enableContinueButton()
        this.uploadStatusTarget.innerHTML = '<i class="fas fa-upload"></i>'
      }
    })
  }

}
