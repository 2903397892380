import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'balance', 'amount', 'reason' ]

  populateAmountAndReason(e) {
    e.preventDefault()

    if (this.balanceTarget.dataset.amount > 0) {
      this.reasonTarget.value = 'write_off'
    } else if (this.balanceTarget.dataset.amount < 0) {
      this.reasonTarget.value = 'overpayment'
    }
    this.amountTarget.value = this.balanceTarget.dataset.amount * -1
  }
}
